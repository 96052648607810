<template>
  <div>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-else class="ap-list_paddings">
      <div v-if="!upcomingItems.length" class="ap-list_no-items">
        <img src="../../../assets/no-items.svg" />
        {{ $t("general.table.noItems") }}
      </div>
      <div v-for="({ date, items }, idx) in upcomingItems" :key="date" class="ap-list_date-fragment">
        <div v-if="idx !== 0" class="ap-list_date-fragment_divider">
          <span class="ap-list_date-fragment_text-outer">{{ date }}</span>
          <v-divider class="ap-list_date-fragment_divider_line" />
        </div>
        <div class="ap-list_wrapper">
          <AppointmentsListItem
            v-for="encounter in items"
            :key="encounter.id"
            :item="encounter"
            :role="role"
            place="upcoming"
            @onButtonClick="joinRoom"
            @onCancel="onCancel"
          />
        </div>
      </div>
    </div>
    <CancelVisit v-model="dialog" :appointmentId="cancelledId" @success="onVisitCancelled" />
  </div>
</template>
<script>
import AppointmentsListItem from "@/components/AppointmentsListItem/index.vue";
import CancelVisit from "@/components/CancelVisit/index";
import { upcomingListMixin } from "@/mixins/upcomingList.js";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "UpcomingAppointments",
  components: {
    AppointmentsListItem,
    CancelVisit,
  },
  data() {
    return {
      role: RolesEnum.Patient,
    };
  },
  mixins: [upcomingListMixin],
};
</script>
